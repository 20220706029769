import {client} from '../axios/client';
import {types} from "../types/types";
import {messageAddNotification} from './messages';

export const updateContact = (contact, token) => {
    return (dispatch) => {

        client.patch(`/contacts/update/${contact._id}`, contact, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function ({data}) {
                dispatch(contactUpdate({
                    contact: data,
                    loading: false
                }))

            })
            .catch(function (error) {
                console.log(error);
            });

    }
}
export const blockContact = (id_agent, appName, contactNumber, token) => {
    return (dispatch) => {

        client.post(`/contact/${appName}/${contactNumber}/block?id_agent=${id_agent}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function ({data}) {
                console.log(data)
                dispatch(contactUpdate({
                    contact: data.data,
                    loading: false
                }))
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
export const getListContacts = (id_customer, token, offset = 0, limit = 5) => {
    return (dispatch) => {
        client.get(`/contacts/${id_customer}`, {
            params: {
                offset,
                limit
            },
            headers: {'Authorization': `Bearer ${token}`}
        })
            .then(({data}) => {
                dispatch(contactsSet({
                    contacts: data.items,
                    loading: false,
                    count: data.count
                }))
            })
    };
}

export const getContactById = (id, token, incoming = false) => {
    console.log("getContactById")
    return (dispatch) => {
        client.get(`/contacts/id/${id}`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(({data}) => {
                dispatch(contactUpdateCurrent(data))
                if (incoming) dispatch(messageAddNotification(data));
            })
    }
}

export const applyQuery = (query, token) => {

    var consulta = JSON.parse(query);


    return (dispatch) => {
        client.post(`/contacts/getQuery/`, consulta, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function ({data}) {

                dispatch(conSet({
                    items: data.items

                }))


            })
    }
}
export const getListContactsByName = (first_name, id_customer, token) => {
    var consulta = `{"first_name": "${first_name}"}`
    consulta = JSON.parse(consulta);
    return (dispatch) => {
        client.post(`/contacts/name/${id_customer}`, consulta, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function ({data}) {
                if (data.count !== 0) {
                    dispatch(contactSet({
                        contacts: data.items,
                        loading: false,
                        count: data.count
                    }))
                } else {
                    // Si data.count es igual a 0, muestra un alert
                    alert("No se han encontrado contactos");
                }

            })
    };
}
export const createContact = (id_customer, data, token) => {
    const payload = {
        first_name: data.firstName,
        second_name: data.secondName,
        last_name: data.lastName,
        phone: data.phoneNumber,
        id_customer: id_customer
    }
    return (dispatch) => {
        client.post(`/contacts/create`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function ({data}) {
                console.log(data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
}

export const transferContact = (data, token) => {
    const payload = {
        id_contact: data.id_contact,
        user_id_agent: data.user_id_agent,
        transfer_id_agent: data.transfer_id_agent,
        body: data.body,
    }
    return (dispatch) => {
        client.post(`/contacts/transfer`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function ({data}) {
                console.log(data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
}
export const addDetails = (contact) => {
    return (dispatch) => {
        dispatch(setSelectedContact(contact))
    }
}

export const getContactsByCategories = (categories, id_customer, token) => async dispatch => {
    try {
        dispatch(setLoadingContacts(true));
        const response = await client.get(`/contacts/categories/${id_customer}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { categories },
            paramsSerializer: params => {
                return Object.keys(params)
                    .map(key => {
                        return params[key].map(value => `${key}=${encodeURIComponent(value)}`).join('&');
                    })
                .join('&');
            },
        });
        const data = response.data;
        if (data.count !== 0) {
            dispatch(contactSet({
                contacts: data.items,
                loading: false,
                count: data.count
            }));
        } else {
            alert("No se han encontrado contactos");
        }
        dispatch({
            type: types.getContactsByCategoriesSuccess,
            payload: {
                contacts: data.items,
                count: data.count
            }
        });
    } catch(error) {
        dispatch({
            type: types.getContactsByCategoriesFailure,
            payload: error.message,
        });
    } finally {
        dispatch(setLoadingContacts(false));
    }
}

export const updateContactsCategories = (id, token) => {

    return async (dispatch) => {
        try {
            const response = await client.patch(`/contacts/update-category/${id}`,{},
                { headers: { Authorization: `Bearer ${token}` } }
            );

            dispatch({
                type: 'UPDATE_CONTACTS_WITH_CATEGORY',
                payload: response.data
            });
        } catch (error) {
            console.error('Error updating contacts with category:', error);
        }
    }
};

export const setLoadingContacts = (isLoading) => ({
    type: types.contactLoading,
    payload: isLoading
})

export const contactsSet = (contacts) => ({
    type: types.contactFetchContacts,
    payload: contacts
})

export const contactUpdate = (payload) => ({
    type: types.contactUpdate,
    payload: payload
})

export const contactUpdateCurrent = (contact) => ({
    type: types.contactUpdateContactsCurrents,
    payload: contact
})

export const setSelectedContact = (contact) => ({
    type: types.contactSetSelectedContact,
    payload: contact
})
export const setSavedContactDetails = (contact) => ({
    type: types.contactSetSavedContactDetails,
    payload: contact
})
export const conSet = (contact) => ({
    type: types.contactsFilter,
    payload: contact
})
export const cleanFilter = (contact) => ({
    type: types.contactsFilterClean,
    payload: contact
})
export const contactSet = (contact) => ({
    type: types.contactSetSearcher,
    payload: contact
})
export const contactClean = () => ({
    type: types.contactCleanList,

})

export const contactClear = () => ({
    type: types.contactClear
})
export const contactSavedContactDetailsClear = () => ({
    type: types.contactSavedContactDetailsClear
})
