import { types } from "../../types/types";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.categoriesFetch:
      return {
        ...state,
        status: 'loading'
      };
    case types.categoriesFetchSuccess:
      return {
        ...state,
        status: 'succeeded',
        items: action.payload,
      }
    case types.categoriesFetchFailure:
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      }
    case types.addCategorySuccess:
      return {
        ...state,
        items: [...state.items, action.payload],
      }
    case types.addCategoryFailure:
      return {
        ...state,
        error: action.payload,
      }
    case types.updateCategorySuccess:
      return {
        ...state,
        items: state.items.map((item) =>
        item.id === action.payload.id ? action.payload : item)
      }
    case types.updateCategoryFailure:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
};

export default categoryReducer;
