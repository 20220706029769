import {types} from "../../types/types";

const INITIAL_STATE = {
    contactsChats: [],
    contacts: [],
    contactsFilters: [],
    loading: false,
    contactDetails: {},
    savedContactDetails: null,
    count: 0,
};

export const contactsReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case types.contactUpdateContactsCurrents: {
            return {
                ...state,
                contactsChats: [
                    payload,
                    ...state.contactsChats.filter(
                        (contact) => contact._id !== payload._id
                    ),
                ],
            };
        }
        case types.contactLoading:
            return {
                ...state,
                loading: payload,
            };
        case types.contactFetchContacts: {
            if (payload.contacts.length > 0) {
                return {
                    ...state,
                    contacts: [...state.contacts, ...payload.contacts],
                    loading: payload.loading,
                    count: payload.count,
                };
            } else {
                return {
                    ...state,
                    loading: payload.loading,
                };
            }
        }
        case types.contactSetSelectedContact: {
            return {
                ...state,
                contactDetails: payload,
            };
        }
        case types.contactSetSavedContactDetails: {
            return {
                ...state,
                savedContactDetails: payload,
            };
        }
        case types.contactUpdate: {
            const index = state.contacts.findIndex(
                (c) => c._id === payload.contact._id
            );
            const updateContacts = [...state.contacts];
            updateContacts[index] = payload.contact;

            const index2 = state.contactsChats.findIndex(
                (c) => c._id === payload.contact._id
            );
            const updateContactsChats = [...state.contactsChats];
            updateContactsChats[index2] = payload.contact;

            if (payload.contact._id === state.contactDetails._id) {
                return {
                    ...state,
                    contacts: updateContacts,
                    contactsChats: updateContactsChats,
                    loading: payload.loading,
                    contactDetails: {...payload.contact},
                };
            }

            return {
                ...state,
                contacts: updateContacts,
                contactsChats: updateContactsChats,
                loading: payload.loading,
            };
        }
        case types.contactChatsGetList: {
            if (payload.contacts.length > 0) {
                return {
                    ...state,
                    contactsChats: [...state.contactsChats, ...payload.contacts],
                    loading: payload.loading,
                };
            }
            return {
                ...state,
                loading: payload.loading,
            };
        }
        case types.contactSetReadMessage: {
            //Busca el index del id_contact en contactsChat
            const index = state.contactsChats.findIndex((c) => c._id === payload);
            //Crea una copia del arreglo contactsChat
            const contactsChat = [...state.contactsChats];

            if (contactsChat[index].unread_message === 0) return state;
            //Actualizamos a cero el contactChat con el id_contact igual al payload
            contactsChat[index] = {
                ...contactsChat[index],
                unread_message: 0,
            };

            //Si el contacto a actualizar tambien esta seleccionado debe actualizar el contactDetails
            if (payload === state.contactDetails._id) {
                return {
                    ...state,
                    contactsChats: contactsChat,
                    contactDetails: {...state.contactDetails, unread_message: 0},
                };
            }

            return {
                ...state,
                contactsChats: contactsChat,
            };
        }
        case types.contactsFilter: {
            return {
                ...state,
                contactsFilters: [payload.items],
            };
        }
        case types.contactsFilterClean: {
            return {
                ...state,
                contactsFilters: [],
            };
        }
        case types.contactSetSearcher: {
            return {
                ...state,
                contacts: [...payload.contacts],
                loading: payload.loading,
                count: payload.count,
            };
        }
        case types.contactCleanList: {
            return {
                ...state,
                contacts: [],
            };
        }
        case types.contactClear: {
            return {
                ...state,
                contactDetails: {},
            };
        }
        case types.contactSavedContactDetailsClear: {
            return {
                ...state,
                savedContactDetails: null,
            };
        }
        case types.getContactsByCategoriesSuccess:
            return {
                ...state,
                contacts: payload.contacts,
                loading: false,
                count: payload.count
            };
        case types.getContactsByCategoriesFailure:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case 'UPDATE_CONTACTS_WITH_CATEGORY': {
            const updatedContacts = state.contacts.map(contact => {
                const updatedContact = payload.find(updated => updated._id === contact._id);
                return updatedContact ? {...contact, categories: updatedContact.categories} : contact;
            });

            const updatedContactsChats = state.contactsChats.map(contact => {
                const updatedContact = payload.find(updated => updated._id === contact._id);
                return updatedContact ? {...contact, categories: updatedContact.categories} : contact;
            });

            const updatedContactDetails = state.contactDetails && payload && Array.isArray(payload)
                ? payload.find(updated => updated._id === state.contactDetails._id)
                    ? {
                        ...state.contactDetails,
                        categories: payload.find(updated => updated._id === state.contactDetails._id).categories
                    }
                    : state.contactDetails
                : state.contactDetails;


            return {
                ...state,
                contacts: updatedContacts,
                contactsChats: updatedContactsChats,
                contactDetails: updatedContactDetails,
            };
        }
            ;
        default:
            return state;
    }
};
